<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" routerLink="/{{routes.Home}}">Zenith Portal</a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
      aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarToggler">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/{{routes.Home}}">
          My Apps <span class="sr-only">(current)</span>
        </a>
      </li>
      <li class="nav-item dropdown" [routerLinkActive]="['active']">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Help
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" [routerLinkActive]="['active']" href="#">User Guide</a>
          <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/{{routes.WhatsNew}}">What's New</a>
          <a class="dropdown-item" [routerLinkActive]="['active']" href="#">Submit Feedback</a>
        </div>
      </li>
      <li class="nav-item d-lg-none">
        <a class="nav-link" role="button" (click)="onLogout()">Logout</a>
      </li>
    </ul>

    <span class="text-light mr-3 d-none d-lg-inline">Welcome, {{ UserName() }}</span>

    <div class="btn-group d-none d-lg-block">
      <div class="btn-group dropleft" role="group">
        <fa-icon [icon]="faUser" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button" class="text-light" />
        <div class="dropdown-menu" style="z-index: 2000">
          <a class="dropdown-item" (click)="onLogout()" role="button">Logout</a>
        </div>
      </div>
    </div>
  </div>
</nav>
