<app-navbar></app-navbar>
<h3 class="m-2">My Apps</h3>

<div class="container-fluid">
  <div class="row">
    <div class="card-body d-flex flex-column p-10 m-2 border rounded">
      <a [href]="this.approvalUrl" target="_blank">
        <span class="mb-15"
          ><fa-icon [icon]="faFileSignature" size="2x"></fa-icon
        ></span>
        <div class="mt-1 font-weight-bold">Approval</div>
      </a>
    </div>

    <div class="card-body d-flex flex-column p-10 m-2 border rounded">
      <a [href]="this.documentsUrl" target="_blank">
        <span class="mb-15"
          ><fa-icon [icon]="faArchive" size="2x"></fa-icon
        ></span>
        <div class="mt-1 font-weight-bold">Documents</div>
      </a>
    </div>
  </div>
</div>
