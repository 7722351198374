import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../interceptors/auth.guard';
import { HomeComponent } from '../components/home/home.component';
import { LoginComponent } from '../components/login/login.component';
import { ControlRoutes } from '../models/constants';
import { WhatsNewComponent } from "../components/whats-new/whats-new.component";

const routes: Routes = [
  { path: ControlRoutes.Login, component: LoginComponent },
  { path: ControlRoutes.Home, component: HomeComponent, canActivate: [AuthGuard] },
  { path: ControlRoutes.WhatsNew, component: WhatsNewComponent, canActivate: [AuthGuard] },
  { path: '**', component: HomeComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
