import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { AuthenticationInterceptor } from '../interceptors/authentication.interceptor';
import { LogService } from 'src/app/services/log.service';
import { AppComponent } from '../app.component';
import { HomeComponent } from '../components/home/home.component';
import { LoginComponent } from '../components/login/login.component';
import { WhatsNewComponent } from '../components/whats-new/whats-new.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { AccordionComponent } from '../components/accordion/accordion.component';

@NgModule({ 
    declarations: [
        AppComponent, 
        HomeComponent, 
        LoginComponent, 
        WhatsNewComponent,
        NavbarComponent,
        AccordionComponent
    ],
    bootstrap: [AppComponent], 
    imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        FontAwesomeModule,
        BrowserAnimationsModule], providers: [
        LogService,
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
