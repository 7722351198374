import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/modules/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

var version = require('../package.json').version; // npm install --save @types/node
console.log('Version: ' + version); // Debug code only, delete later

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
