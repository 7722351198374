import { Component } from '@angular/core';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  faArchive = faArchive;
  faFileSignature = faFileSignature;
  approvalUrl: string = '';
  documentsUrl: string = '';

  constructor() {
    if (environment.production) {
      this.approvalUrl = 'https://approval.zis.com';
      this.documentsUrl = 'https://docs.zis.com';
    } else {
      this.approvalUrl = 'https://approval-devtest.zis.com';
      this.documentsUrl = 'https://docs-devtest.zis.com';
    }
  }
}
